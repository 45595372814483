'use client'

import { useState } from 'react'
import styles from './image.module.css'
import Image from 'next/image'
import { Skeleton } from '@radix-ui/themes'
import clsx from 'clsx'

export default function ZbImage({
  src = '/600x400',
  zoomImage = false,
  width = 120,
  height = 120,
  alt = 'ZebraBox Image',
  className,
  loading = 'eager',
  fill = false,
}: {
  src: string
  zoomImage?: boolean
  width?: number
  height?: number
  alt?: string
  className?: string
  loading?: 'lazy' | 'eager'
  fill?: boolean
}) {
  const [isLoading, setIsLoading] = useState(true)

  return (
    <div
      className={clsx(
        styles.imageContainer,
        {
          [styles.zoomableImg]: zoomImage,
        },
        className
      )}
    >
      {isLoading && (
        <Skeleton className={clsx(styles.image, styles.imageSkeleton)} />
      )}
      <figure>
        <Image
          src={src}
          alt={alt}
          style={{ objectFit: 'cover' }}
          className={clsx(styles.image, className)}
          onLoad={() => setIsLoading(false)}
          loading={loading}
          priority={loading === 'eager'}
          sizes="(max-width: 768px) 100vw, 50vw"
          quality={100}
          {...(fill ? { fill: true } : { width: width, height: height })}
        />
      </figure>
      {alt && <figcaption className={styles.altText}>{alt}</figcaption>}
    </div>
  )
}
