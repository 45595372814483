'use client'

import { forwardRef, ReactNode } from 'react'
import { Link } from '@/i18n/routing'
import NextLink from 'next/link'
import clsx from 'clsx'
import styles from './link.module.css'
import { useLocale } from 'next-intl'

export type ZbLinkProps = {
  linkInNewTab?: boolean
  children?: ReactNode
  type?: 'unstyled' | 'button' | 'base' | 'arrow' | 'secondaryButton'
  to?: string
  color?: 'gold' | 'black' | ''
  size?: 'small' | 'medium' | 'large' | 'fit-content' | ''
  locale?: string
  className?: string
  isEmail?: boolean
  isExternal?: boolean
  fromSearch?: boolean
  download?: boolean | string
  onClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
}

const DOWNLOADABLE_EXTENSIONS_SET = new Set([
  '.pdf',
  '.doc',
  '.docx',
  '.xls',
  '.xlsx',
  '.ppt',
  '.pptx',
  '.zip',
  '.rar',
  '.7z',
  '.txt',
  '.csv',
  '.mp3',
  '.mp4',
  '.mov',
  '.avi',
  '.png',
  '.jpg',
  '.jpeg',
  '.gif',
])

const LINK_STYLES = {
  unstyled: 'unstyledLink',
  button: 'linkAsButton',
  base: 'baseLink',
  arrow: 'arrowStyled',
  secondaryButton: 'linkAsButton',
} as const

const ZbLink = forwardRef<HTMLAnchorElement, ZbLinkProps>((props, ref) => {
  const {
    to,
    type = 'unstyled',
    size = 'medium',
    className,
    children,
    linkInNewTab,
    isEmail,
    isExternal,
    download = false,
    onClick,
    ...rest
  } = props
  const currentLocale = useLocale()
  const linkStyle = LINK_STYLES[type] ?? 'unstyledLink'

  const isDownloadableLink = (url?: string): boolean => {
    if (!url) return false
    const ext = url.split('.').pop()?.toLowerCase()
    return ext ? DOWNLOADABLE_EXTENSIONS_SET.has(`.${ext}`) : false
  }

  const removeDuplicateLocale = (url: string) => {
    if (download || isDownloadableLink(url)) {
      return url
    }

    const localePattern = new RegExp(`/${currentLocale}/`, 'g')
    const cleanedUrl = url.replace(localePattern, '/')

    return cleanedUrl
  }

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (onClick && rest.fromSearch) {
      onClick(e)
      return
    }

    if (rest.locale && !isDownloadableLink(to)) {
      e.preventDefault()
      const cleanedUrl = removeDuplicateLocale(to || '')
      window.location.href = `/${rest.locale}${cleanedUrl}`
      return
    }

    if (download || (isDownloadableLink(to) && !linkInNewTab)) {
      return
    }

    if (isEmail) {
      e.preventDefault()
      window.location.href = `mailto:${to}`
      return
    }

    if (isExternal) {
      e.preventDefault()
      window.location.href = removeDuplicateLocale(to || '')
      return
    }
  }

  const getHref = (url?: string): string => {
    if (!url) return ''
    if (isEmail) return `mailto:${url}`
    return removeDuplicateLocale(url)
  }

  const href = getHref(to)
  const isDownloadable = download || isDownloadableLink(to)

  const commonProps = {
    href,
    className: clsx(
      linkStyle === 'unstyledLink' ? styles.unstyledLink : styles[linkStyle],
      styles[size],
      type === 'secondaryButton' && styles.secondaryButton,
      className
    ),
    target: linkInNewTab ? '_blank' : '_self',
    rel: linkInNewTab ? 'noopener noreferrer' : undefined,
    onClick: handleClick,
    ref,
  }

  if (isDownloadable) {
    return (
      <NextLink {...commonProps} download={true} target="_blank">
        {children}
      </NextLink>
    )
  }

  if (isExternal) {
    return (
      <NextLink
        className={clsx(
          linkStyle === 'unstyledLink'
            ? styles.unstyledLink
            : styles[linkStyle],
          styles[size],
          type === 'secondaryButton' && styles.secondaryButton,
          className
        )}
        href={to}
      >
        {children}
      </NextLink>
    )
  }

  return (
    <Link {...rest} {...commonProps}>
      {children}
    </Link>
  )
})

ZbLink.displayName = 'ZbLink'

export default ZbLink
