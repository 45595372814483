'use client'

import styles from './blogpreview.module.css'
import { format } from 'date-fns'
import { Flex } from '@radix-ui/themes'
import DOMPurify from 'isomorphic-dompurify'
import clsx from 'clsx'
import ZbImage from '@/components/ZbImage'
import { drupalBaseUrl } from '@/lib/config'
import { wrapTables } from '@/hooks/useSanitizedHtml'

export default function BlogPreview({
  title,
  text,
  date,
  image,
  category,
}: {
  title: string
  text?: string
  date: string
  image?: string
  category?: string
}) {
  const dateFormatted = format(new Date(date), 'dd/MM/yyyy, HH:mm')

  return (
    <div className={`${styles.blogPreviewContainer}`}>
      <Flex direction="row" width="85%">
        <figure className={`${styles.imageContainer}`}>
          <ZbImage
            src={`${drupalBaseUrl}${image}` || '/zebra-texture.svg'}
            width={90}
            height={90}
            alt={title}
            className={styles.image}
          />
        </figure>
        <Flex
          direction="column"
          gap="5px"
          className={`${styles.contentContainer}`}
        >
          <div className={styles.dateContainer}>
            <p className={`${styles.date}`}>{dateFormatted}</p>
          </div>
          {category && (
            <div className={styles.categoryContainer}>
              <p className={`${styles.category}`}>{category}</p>
            </div>
          )}
          <div className={`${styles.titleContainer}`}>
            <h6 className={`${styles.title}`}>{title}</h6>
          </div>
          <div
            className={clsx(styles.textContainer, styles.text)}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(wrapTables(text || '')),
            }}
          />
        </Flex>
      </Flex>
    </div>
  )
}
